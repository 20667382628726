import React, { useEffect, useState } from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import cx from "classnames";
import checkIfImageExists from "../../utils/checkIfImageExists";

const RecommendationItems = ({
  sliderdata,
  index,
  placeOrder,
  productsSupabase,
  onDispatchCartTax,
}) => {
  const defaultImage = `/assets/${process.env.REACT_APP_MODE}/logo/logo2.png`;

  const [imageUrl, setImageUrl] = useState();

  const findProduct = productsSupabase?.find(
    (record) => record?.name === sliderdata?.item_name
  );

  const originalImageUrl = findProduct?.image_url;

  useEffect(() => {
    checkIfImageExists(originalImageUrl, (exists) => {
      if (exists) {
        setImageUrl(originalImageUrl);
      } else {
        setImageUrl(defaultImage);
      }
    });
  }, [defaultImage, originalImageUrl]);

  const isSoldOut = React.useMemo(() => {
    return (
      sliderdata.is_soldout === "Y" ||
      sliderdata.is_emenu_disable === "Y" ||
      (sliderdata.is_avl_limit_check === "Y" && sliderdata.bal_qty === 0)
    );
  }, [
    sliderdata.bal_qty,
    sliderdata.is_avl_limit_check,
    sliderdata.is_emenu_disable,
    sliderdata.is_soldout,
  ]);

  useEffect(() => {
    if (sliderdata) {
      onDispatchCartTax?.(sliderdata);
    }
  }, [sliderdata]);

  return (
    <div className="item relative px-1" data-value={index}>
      <BsPlusCircleFill
        color="var(--primary-color)"
        className={cx("recommendation-add-button", {
          disabled: !isSoldOut,
        })}
        onClick={() => !isSoldOut && placeOrder(sliderdata)}
      />

      {productsSupabase?.length > 0 && imageUrl && (
        <img src={imageUrl} className="popular-img" alt="" />
      )}
      {/* <div className="mt-2 text-start">
        <img
          src={`/assets/commonImages/${config?.star}.png`}
          alt=""
          className="me-2 ms-2"
        />
        <img src={`/assets/commonImages/${config?.chef}.png`} alt="" />
      </div> */}
      <div className="d-grid text-start">
        <label className="recom-text mt-1 text-uppercase text-truncate">
          {sliderdata.item_desc}
        </label>
        <label className="recom-price">
          $
          {Number(
            sliderdata?.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price
          ).toFixed(2)}
        </label>
      </div>
    </div>
  );
};

export default RecommendationItems;
