import "./App.css";
import "./dynamic_css/common.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SplashScreen from "./pages/splash_screen/splash_screen";
import Device from "./component/device";
import Sidebar from "./component/sidebar";
import Home from "./pages/home/home";
import CustomizeDish from "./pages/customize_dish/customize_dish";
import Cart from "./pages/cart/cart";
import Payment from "./pages/payment/payment";
import Paymentsuccess from "./pages/payment/paymentsuccess";
import Thankyou from "./pages/payment/thankyou";
import OrderHistory from "./pages/orderhistory/orderhistory";
import Orderdetails from "./pages/orderhistory/orderdetails";
import Login from "./pages/login/login";
import QRCodeError from "./pages/splash_screen/qrCodeError";
import DemoPage from "./pages/demopage/demopage";
import TOS from "./pages/common/tos";
import PrivacyPolicy from "./pages/common/privacy";
import PaymentOptions from "./pages/payment/PaymentOptions";
import { ToastContainer } from "react-toastify";
import MyOrders from "./pages/myOrders/myOrders";
import StoreNameError from "./pages/splash_screen/storenameError";
import PayLaterOrdersPage from "./pages/payLaterOrders/payLaterOrders";
import PaymentCallback from "./pages/payment/paymentCallback";
import ConfettiEffect from "./component/ConfettiEffect/ConfettiEffect";

function App() {
  return (
    <>
      <Sidebar />
      <Device />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        <Routes>
          <Route exact path="/" element={<SplashScreen />} />
          <Route exact path="Login" element={<Login />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/customize-dish" element={<CustomizeDish />} />
          <Route exact path="/cart" element={<Cart />} />
          <Route exact path="/payments" element={<Payment />} />
          <Route exact path="/payment-success" element={<Paymentsuccess />} />
          <Route exact path="/thankyou" element={<Thankyou />} />
          <Route exact path="/order-history" element={<OrderHistory />} />
          <Route exact path="/order-details" element={<Orderdetails />} />
          <Route exact path="/qr-not-valid" element={<QRCodeError />} />
          <Route
            exact
            path="/storename-not-valid"
            element={<StoreNameError />}
          />
          <Route exact path="/demopage" element={<DemoPage />} />
          <Route exact path="/terms-of-use" element={<TOS />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/payment-options" element={<PaymentOptions />} />
          <Route exact path="/my-orders" element={<MyOrders />} />
          <Route
            exact
            path="/pay-later-orders"
            element={<PayLaterOrdersPage />}
          />
          <Route exact path="/payment-callback" element={<PaymentCallback />} />
        </Routes>
      </Router>
      <ConfettiEffect />
    </>
  );
}

export default App;
