import React, { useEffect, useState } from "react";
import { storePromoList } from "../services/tableQRServices";
import moment from "moment";
import { useSelector } from "react-redux";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

const usePromotion = () => {
  const { cart } = useSelector((state) => state.cart);
  const { posFullItemList } = useSelector((state) => state.pos);

  const [promotionConfig, setPromotionConfig] = useState([]);

  const handleGetPromotionConfig = async () => {
    try {
      const { data } = await storePromoList();

      setPromotionConfig(data?.output?.filter((record) => record?.apply_web));
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckingTimeSlot = (promoConfig) => {
    const today = new Date();
    let flag = true;

    if (
      moment(new Date(promoConfig?.promo_st_date)).isAfter(moment(today)) ||
      moment(new Date(promoConfig?.promo_ed_date)).isBefore(moment(today))
    ) {
      flag = false;

      return;
    }

    const currentDay = moment(today).format("ddd").toUpperCase();

    if (
      !promoConfig?.time_slot_dtls
        ?.map((record) => record?.day_info)
        ?.includes(currentDay)
    ) {
      flag = false;

      return;
    }

    const findDayInfo = promoConfig?.time_slot_dtls?.find(
      (record) => record?.day_info === currentDay
    );

    if (
      findDayInfo.st_time > moment().format("HH:mm:ss") ||
      findDayInfo.ed_time < moment().format("HH:mm:ss")
    ) {
      flag = false;

      return;
    }

    flag = true;

    return flag;
  };

  const handleLogicPromotionNewItem = (data) => {
    let formatData = {
      ...data,
    };

    const finalPrice = formatData?.originalPrice || formatData?.price;
    const sellingDetails = formatData.item.selling_uom_dtls?.[0];

    const convertPriceObject = {
      ...sellingDetails,
      price_dtls: [
        {
          ...sellingDetails?.price_dtls?.[0],
          dine_in_price_dc: finalPrice,
          takeaway_price_dc: finalPrice,
        },
      ],
    };

    formatData = {
      ...formatData,
      item: {
        ...formatData.item,
        selling_uom_dtls: [convertPriceObject],
        promo_name: undefined,
      },
      price: finalPrice,
      isNew: true,
    };

    return formatData;
  };

  const handleConvertPromotionItem = (data) => {
    let formatData = {
      ...data,
    };

    promotionConfig?.forEach((promoConfig) => {
      if (!handleCheckingTimeSlot(promoConfig)) {
        return;
      }

      switch (promoConfig?.criteria_type) {
        case "LD": {
          const categoryLowestPrice = promoConfig?.item_menu_category_dtls?.[0];
          const itemBaseOnCategoryList = cart?.filter(
            (record) =>
              record?.item?.category_code === categoryLowestPrice?.category_code
          );

          if (
            itemBaseOnCategoryList?.find(
              (record) =>
                record?.id === formatData?.id &&
                record?.uuid &&
                formatData?.uuid
            )
          ) {
            formatData = handleLogicPromotionNewItem(formatData);
          }

          break;
        }

        default: {
          break;
        }
      }
    });

    return formatData;
  };

  const handleConvertPromotionModifierItem = (data, modifierQtyType) => {
    let formatData = {
      ...data,
    };

    promotionConfig?.forEach((promoConfig) => {
      if (!handleCheckingTimeSlot(promoConfig)) {
        return;
      }

      if (promoConfig?.criteria_type === "LD") {
        const categoryLowestPrice = promoConfig?.item_menu_category_dtls?.[0];
        const itemBaseOnCategoryList = cart?.filter(
          (record) =>
            record?.item?.category_code === categoryLowestPrice?.category_code
        );

        if (
          itemBaseOnCategoryList?.find(
            (record) =>
              record?.id === formatData?.id && record?.uuid && formatData?.uuid
          ) &&
          modifierQtyType !== "minus"
        ) {
          formatData = handleLogicPromotionNewItem(formatData);
        }

        return;
      }

      if (promoConfig?.criteria_type === "SP") {
        const categorySpecialPrice = promoConfig?.item_menu_category_dtls?.[0];
        const itemBaseOnCategoryList = cart?.filter(
          (record) =>
            record?.item?.category_code === categorySpecialPrice?.category_code
        );

        if (
          itemBaseOnCategoryList?.find(
            (record) =>
              record?.id === formatData?.id && record?.uuid && formatData?.uuid
          ) &&
          modifierQtyType !== "minus"
        ) {
          formatData = handleLogicPromotionNewItem(formatData);
        }

        return;
      }

      if (formatData?.item?.promo_name) {
        return;
      }

      const creteriaItemList = promoConfig?.creteria_item_dtls || [];
      const creteriaItemCodesPromo = creteriaItemList?.map(
        (record) => record?.item_no
      );
      const findItem = cart?.find(
        (record) => record?.uuid === formatData?.uuid
      );
      const findCreteriaItem = creteriaItemList?.find(
        (record) => record?.item_no === formatData?.id
      );

      switch (promoConfig?.criteria_type) {
        case "FI": {
          if (creteriaItemCodesPromo.includes(formatData.item?.item_no)) {
            const checkingLimitFreeItem = cart?.find(
              (record) => record?.id === formatData?.id && record?.price === 0
            );

            if (
              checkingLimitFreeItem &&
              checkingLimitFreeItem?.qty +
                (modifierQtyType === "minus" ? -1 : 0) ===
                findCreteriaItem?.qty
            ) {
              return;
            }

            if (
              !findItem ||
              (findCreteriaItem &&
                findItem?.qty + (modifierQtyType === "minus" ? -1 : 0) <
                  findCreteriaItem?.qty)
            ) {
              const findItem = cart?.find(
                (record) => record?.id === formatData?.id
              );
              const findCreteriaItem = (
                promoConfig?.creteria_item_dtls || []
              )?.find((record) => record?.item_no === formatData?.id);

              let sellingDetails = formatData.item.selling_uom_dtls?.[0];
              const convertPriceObject = {
                ...sellingDetails,
                price_dtls: [
                  {
                    ...sellingDetails?.price_dtls?.[0],
                    dine_in_price_dc: 0,
                    takeaway_price_dc: 0,
                  },
                ],
              };

              formatData = {
                ...formatData,
                item: {
                  ...formatData.item,
                  selling_uom_dtls: [convertPriceObject],
                  promo_name: promoConfig?.promo_name,
                  isNotIncrement:
                    findItem?.qty + (modifierQtyType === "minus" ? -1 : 1) ===
                    findCreteriaItem?.qty,
                },
                price: 0,
              };
            }
          }

          break;
        }

        case "FO": {
          if (creteriaItemCodesPromo.includes(formatData.item?.item_no)) {
            const calculateFreeLimitItem =
              cart?.reduce((a, b) => (a += b?.qty || 0), 0) +
              (modifierQtyType === "minus" ? -1 : 1);

            if (
              calculateFreeLimitItem <=
              promoConfig?.criteria_free_item_qty_limit
            ) {
              let sellingDetails = formatData.item.selling_uom_dtls?.[0];
              const convertPriceObject = {
                ...sellingDetails,
                price_dtls: [
                  {
                    ...sellingDetails?.price_dtls?.[0],
                    dine_in_price_dc: 0,
                    takeaway_price_dc: 0,
                  },
                ],
              };

              formatData = {
                ...formatData,
                item: {
                  ...formatData.item,
                  selling_uom_dtls: [convertPriceObject],
                  promo_name: promoConfig?.promo_name,
                  isNotIncrement:
                    calculateFreeLimitItem ===
                    promoConfig?.criteria_free_item_qty_limit,
                },
                price: 0,
              };
            }
          }

          break;
        }

        default:
          break;
      }
    });

    return formatData;
  };

  const handleConvertPromotionTotal = (data) => {
    let formatData = data;

    promotionConfig?.forEach((promoConfig) => {
      if (!handleCheckingTimeSlot(promoConfig)) {
        return;
      }

      switch (promoConfig?.criteria_type) {
        case "TD": {
          const percentage = promoConfig?.criteria_disc_value / 100;
          const correctPercentage = 1 - percentage;

          formatData = formatData * correctPercentage;

          break;
        }

        default:
          break;
      }
    });

    return formatData;
  };

  const handleConvertPromotionCart = (data) => {
    let formatData = [...data];

    promotionConfig?.forEach((promoConfig) => {
      if (!handleCheckingTimeSlot(promoConfig)) {
        return;
      }

      const categoryCodesPromo = (
        promoConfig?.item_menu_category_dtls || []
      )?.map((record) => record?.category_code);
      const itemCodesPromo = (promoConfig?.item_dtls || [])?.map(
        (record) => record?.item_no
      );

      switch (promoConfig?.criteria_type) {
        case "LD": {
          const categoryLowestPrice = promoConfig?.item_menu_category_dtls?.[0];
          const itemBaseOnCategoryList = (formatData || [])
            ?.filter(
              (record) =>
                record?.item?.category_code ===
                categoryLowestPrice?.category_code
            )
            ?.sort(
              (a, b) =>
                (a?.originalPrice || a?.price) * a?.qty -
                (b?.originalPrice || b?.price) * b?.qty
            );
          const divideItemBaseOnQty = Math.floor(
            itemBaseOnCategoryList?.length / categoryLowestPrice?.qty
          );
          let itemNeedApplyPromoList = itemBaseOnCategoryList?.slice(
            0,
            divideItemBaseOnQty
          );
          const itemRemainList = itemBaseOnCategoryList?.slice(
            divideItemBaseOnQty,
            itemBaseOnCategoryList?.length
          );

          itemNeedApplyPromoList = itemNeedApplyPromoList?.map((record) => {
            if (record?.item?.promo_name) {
              return record;
            }

            const addonPrice = record?.addOnValues
              ?.slice(1, record?.addOnValues?.length)
              ?.reduce((a, b) => (a += b?.price_dtls * b?.modifier_qty), 0);
            const formatPrice = record?.price - addonPrice;

            const sellingDetails = record.item.selling_uom_dtls?.[0];
            let finalPrice = formatPrice;
            let finalDineInPrice =
              sellingDetails?.price_dtls?.[0]?.dine_in_price;
            let finalTakeAwayPrice =
              sellingDetails?.price_dtls?.[0]?.takeaway_price;

            switch (promoConfig?.criteria_disc_type) {
              case "P": {
                const percentage = promoConfig?.criteria_disc_value / 100;
                const correctPercentage = 1 - percentage;

                finalDineInPrice = finalDineInPrice * correctPercentage;
                finalTakeAwayPrice = finalTakeAwayPrice * correctPercentage;
                finalPrice = finalPrice * correctPercentage;

                break;
              }

              default:
                break;
            }

            const convertPriceObject = {
              ...sellingDetails,
              price_dtls: [
                {
                  ...sellingDetails?.price_dtls?.[0],
                  dine_in_price_dc: finalDineInPrice,
                  takeaway_price_dc: finalTakeAwayPrice,
                },
              ],
            };

            return {
              ...record,
              item: {
                ...record.item,
                selling_uom_dtls: [convertPriceObject],
                promo_name: promoConfig?.promo_name,
                disc_type: promoConfig?.criteria_disc_type,
                disc_value: promoConfig?.criteria_disc_value,
              },
              price: finalPrice + addonPrice,
              originalPrice: record?.price,
            };
          });

          const finalFormatData = [
            ...itemNeedApplyPromoList,
            ...itemRemainList,
          ];

          formatData = formatData?.map((record) => {
            const findItem = finalFormatData?.find(
              (item) => item?.id === record?.id && item?.uuid === record?.uuid
            );

            if (findItem) {
              return findItem;
            }

            return record;
          });

          break;
        }

        case "SP": {
          const categorySpecialPrice =
            promoConfig?.item_menu_category_dtls?.[0];
          const firstItemBaseOnCategoryList = [];
          const lastItemBaseOnCategoryList = [];

          formatData.forEach((item) => {
            if (
              item?.item?.category_code === categorySpecialPrice?.category_code
            ) {
              firstItemBaseOnCategoryList.push(item);
            } else {
              lastItemBaseOnCategoryList.push(item);
            }
          });

          const itemBaseOnCategoryList = firstItemBaseOnCategoryList?.sort(
            (a, b) =>
              (a?.originalPrice || a?.price) * a?.qty -
              (b?.originalPrice || b?.price) * b?.qty
          );

          const formatSpecialPriceList = [];

          if (itemBaseOnCategoryList?.length < categorySpecialPrice?.qty) {
            return;
          }

          const numberLoop = Math.ceil(
            itemBaseOnCategoryList?.length / categorySpecialPrice?.qty
          );
          const findDiscountItem = posFullItemList?.find(
            (record) => record?.item_no === promoConfig?.criteria_promo_item_no
          );

          Array.from({ length: numberLoop }).forEach((_, index) => {
            let groupSpecialPriceList = itemBaseOnCategoryList?.slice(
              index * categorySpecialPrice?.qty,
              (index + 1) * categorySpecialPrice?.qty
            );

            if (groupSpecialPriceList?.length === categorySpecialPrice?.qty) {
              groupSpecialPriceList = groupSpecialPriceList.map((record) => {
                if (record?.item?.promo_name) {
                  return record;
                }

                return {
                  ...record,
                  item: {
                    ...record.item,
                    promo_name: promoConfig?.promo_name,
                  },
                  originalPrice: record?.price,
                };
              });

              const totalPriceFromSpecialPriceList =
                groupSpecialPriceList?.reduce((a, b) => (a += b?.price), 0);
              const addonPrice = groupSpecialPriceList
                ?.map((item) =>
                  item?.addOnValues
                    ?.slice(1, item?.addOnValues?.length)
                    ?.reduce(
                      (a, b) => (a += b?.price_dtls * b?.modifier_qty),
                      0
                    )
                )
                ?.reduce((a, b) => (a += b), 0);

              groupSpecialPriceList.push({
                uuid: uuidv4(),
                id: findDiscountItem?.item_no,
                addOnValues: [],
                item: {
                  ...findDiscountItem,
                  promo_name: promoConfig?.promo_name,
                },
                qty: 1,
                price: Number(
                  (
                    promoConfig?.criteria_spl_price -
                    totalPriceFromSpecialPriceList +
                    addonPrice
                  ).toFixed(2)
                ),
              });
            }

            formatSpecialPriceList.push(groupSpecialPriceList);
          });

          formatData = [].concat.apply(
            [],
            [...formatSpecialPriceList, ...lastItemBaseOnCategoryList]
          );

          break;
        }

        case "ID": {
          formatData = formatData.map((record) => {
            let formatItem = {
              ...record,
            };

            if (itemCodesPromo?.includes(formatItem.item?.item_no)) {
              return formatItem;
            }

            if (categoryCodesPromo?.includes(formatItem.item?.category_code)) {
              const addonPrice = formatItem?.addOnValues
                ?.slice(1, formatItem?.addOnValues?.length)
                ?.reduce((a, b) => (a += b?.price_dtls * b?.modifier_qty), 0);
              let formatPrice = formatItem?.price - addonPrice;
              const sellingDetails = formatItem.item.selling_uom_dtls?.[0];
              let finalDineInPrice =
                sellingDetails?.price_dtls?.[0]?.dine_in_price;
              let finalTakeAwayPrice =
                sellingDetails?.price_dtls?.[0]?.takeaway_price;

              switch (promoConfig?.criteria_disc_type) {
                case "A": {
                  if (formatPrice > promoConfig?.criteria_disc_value) {
                    formatPrice =
                      formatPrice - promoConfig?.criteria_disc_value;
                  }

                  break;
                }

                case "P": {
                  const percentage = promoConfig?.criteria_disc_value / 100;
                  const correctPercentage = 1 - percentage;

                  formatPrice = formatPrice * correctPercentage;

                  break;
                }

                default: {
                  break;
                }
              }

              const convertPriceObject = {
                ...sellingDetails,
                price_dtls: [
                  {
                    ...sellingDetails?.price_dtls?.[0],
                    dine_in_price_dc: finalDineInPrice,
                    takeaway_price_dc: finalTakeAwayPrice,
                  },
                ],
              };

              formatItem = {
                ...formatItem,
                item: {
                  ...formatItem.item,
                  selling_uom_dtls: [convertPriceObject],
                  promo_name: promoConfig?.promo_name,
                  disc_type: promoConfig?.criteria_disc_type,
                  disc_value: promoConfig?.criteria_disc_value,
                },
                price: formatPrice + addonPrice,
                originalPrice: data?.price,
              };
            }

            return formatItem;
          });

          break;
        }

        default:
          break;
      }
    });

    return formatData;
  };

  const handleConvertPromotionCartItem = (data) => {
    let formatData = {
      ...data,
    };

    promotionConfig?.forEach((promoConfig) => {
      if (!handleCheckingTimeSlot(promoConfig)) {
        return;
      }

      if (formatData?.item?.promo_name) {
        return;
      }

      const creteriaItemList = promoConfig?.creteria_item_dtls || [];
      const creteriaItemCodesPromo = creteriaItemList?.map(
        (record) => record?.item_no
      );

      switch (promoConfig?.criteria_type) {
        case "FO": {
          if (
            creteriaItemCodesPromo.includes(formatData.item?.item_no) &&
            formatData?.item?.promo_name
          ) {
            const calculateFreeLimitItem = cart
              ?.filter((record) => !record?.price)
              ?.reduce((a, b) => (a += b?.qty || 0), 0);

            let sellingDetails = formatData.item.selling_uom_dtls?.[0];
            const convertPriceObject = {
              ...sellingDetails,
              price_dtls: [
                {
                  ...sellingDetails?.price_dtls?.[0],
                  dine_in_price_dc: 0,
                  takeaway_price_dc: 0,
                },
              ],
            };

            formatData = {
              ...formatData,
              item: {
                ...formatData.item,
                selling_uom_dtls: [convertPriceObject],
                promo_name: promoConfig?.promo_name,
                isNotIncrement:
                  calculateFreeLimitItem ===
                  promoConfig?.criteria_free_item_qty_limit,
              },
              price: 0,
            };
          }

          break;
        }

        default:
          break;
      }
    });

    return formatData;
  };

  const handleGroupCart = (data, usingPromotion) => {
    const formatData = [...data];
    let newFormatData = [];

    formatData.forEach((record) => {
      const itemInCart = newFormatData.find(
        (item) =>
          item.id === record.id &&
          item.addOnValues.length === record.addOnValues.length &&
          (usingPromotion ? true : !item?.item?.promo_name) &&
          _.isEqual(
            _.map(item.addOnValues, "item_no"),
            _.map(record.addOnValues, "item_no")
          )
      );

      if (itemInCart) {
        const itemInCartIndex = newFormatData.findIndex(
          (item) =>
            item.id === record.id &&
            item.addOnValues.length === record.addOnValues.length &&
            (usingPromotion ? true : !item?.item?.promo_name) &&
            _.isEqual(
              _.map(item.addOnValues, "item_no"),
              _.map(record.addOnValues, "item_no")
            )
        );

        newFormatData[itemInCartIndex] = {
          ...itemInCart,
          qty: itemInCart.qty + 1,
          uuidList: [...itemInCart?.uuidList, record?.uuid],
        };
      } else {
        newFormatData.push({
          ...record,
          uuidList: [record?.uuid],
        });
      }
    });

    return newFormatData;
  };

  useEffect(() => {
    handleGetPromotionConfig();
  }, []);

  console.log("promotionConfig", promotionConfig);

  return {
    handleConvertPromotionItem,
    handleConvertPromotionTotal,
    handleConvertPromotionCart,
    handleConvertPromotionModifierItem,
    handleConvertPromotionCartItem,
    handleGroupCart,
  };
};

export default usePromotion;
