import React, { useEffect, useState } from "react";
import { AnimateOnChange } from "react-animation";
import { useNavigate } from "react-router-dom";
import {
  QRCodeValidation,
  generateQRCode,
  storeMenuButton,
  storeMenuItems,
  storeStockList,
  userSessionId,
} from "../../services/tableQRServices";
import { STORAGEKEY } from "../../constants";
import moment from "moment";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetState } from "../../redux/cartSlice";
import { getProducts } from "../../services/supabase/product";
import {
  resetPosState,
  setPosFullItemList,
  setPosMenuButton,
  setPosStoreStockList,
  setSupabaseProducts,
} from "../../redux/posSlice";
import useBrandConfig from "../../hooks/useBrandConfig";
import { setConfig } from "../../redux/appSlice";

function SplashScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = window.location.search;
  const [loading, setLoading] = useState(true);
  const [isQRError, setIsQRError] = useState(false);

  const { storeClosed } = useSelector((state) => state.app);

  const { config, handleCheckStoreClosed } = useBrandConfig();

  let storeName = new URLSearchParams(params).get("outlet");
  let tableNo = new URLSearchParams(params).get("table_no");
  const qrCode = new URLSearchParams(params).get("QRCode");

  // temp support
  const storeNameTemp = new URLSearchParams(params).get("storename");
  if (storeNameTemp && storeNameTemp.length > 0) {
    storeName = storeNameTemp;
  }
  const tableNoTemp = new URLSearchParams(params).get("tableno");
  if (tableNoTemp && tableNoTemp.length > 0) {
    tableNo = tableNoTemp;
  }

  // ? condition redirect after splash time over
  const QRCodeValidationFunction = (qrCode, tableNo) => {
    QRCodeValidation(qrCode, tableNo)
      .then((res) => {
        const result = res?.data?.data[0];
        console.log("result", result);
        if (result.result === "FAIL") {
          setIsQRError(true);
          navigate("/qr-not-valid");
        } else {
          navigate("/home");
        }
      })
      .catch(() => {});
  };

  const sessionCheckFunction = () => {
    if (
      // qrCode &&
      // qrCode.length > 0 &&
      // process.env.REACT_APP_MODE !== "pavilion"
      qrCode &&
      qrCode.length > 0
    ) {
      QRCodeValidationFunction(qrCode, tableNo);
    } else if (
      // (!qrCode || qrCode.length <= 0) &&
      // process.env.REACT_APP_MODE === "pavilion"
      !qrCode ||
      qrCode.length <= 0
    ) {
      navigate("/home");
    } else {
      navigate("/qr-not-valid");
    }
  };

  const handleLocalStorage = async () => {
    dispatch(resetState());
    dispatch(resetPosState());
    dispatch(setConfig({}));

    localStorage.removeItem(STORAGEKEY.TABLE_NO);
    localStorage.removeItem(STORAGEKEY.STORE_NAME);
    localStorage.removeItem(STORAGEKEY.DINING_MODE);

    localStorage.clear();

    localStorage.setItem(
      STORAGEKEY.STORE_NAME,
      storeName && storeName.length > 0 ? storeName : "BUGIS JUNCTION"
    );
    localStorage.setItem(STORAGEKEY.QR_CODE, qrCode);
    localStorage.setItem(STORAGEKEY.TABLE_NO, tableNo || "ZD999");

    await fetchSupabaseProducts();
  };

  const fetchSupabaseProducts = async () => {
    const resp = await getProducts(process.env.REACT_APP_MODE);

    if (resp?.data?.length !== 0) {
      // look for items where is_top_pick is true
      const topPicks = resp?.data?.filter((item) => item.is_top_pick);
      const topPicksIds = topPicks?.map((item) => item.pos_item_no);

      dispatch(setSupabaseProducts(topPicksIds));
    }
  };

  useEffect(() => {
    if (config?.qr_type === "dynamic" && (!qrCode || qrCode.length <= 0)) {
      navigate("/qr-not-valid");
    }
  }, [config?.qr_type, navigate, qrCode]);

  useEffect(() => {
    if (!storeName) {
      navigate("/storename-not-valid");
    } else {
      handleLocalStorage();
      setLoading(true);
      userSessionId()
        .then((res) => {
          localStorage.setItem(STORAGEKEY.SESSION_ID, res);

          handleCheckStoreClosed();

          setLoading(false);
        })
        .catch((_err) => {
          setLoading(false);
        });
    }
  }, []);

  const formik = useFormik({
    initialValues: { terms_services: false, promotional: true },
    // validationSchema: Yup.object({
    //   terms_services: Yup.boolean().oneOf(
    //     [true],
    //     "The terms of service must be accepted"
    //   ),
    //   // promotional: Yup.boolean().oneOf(
    //   //   [true],
    //   //   "I do not wish to receive any marketing or promotional materials must be accepted"
    //   // ),
    // }),
    onSubmit: async (values) => {
      sessionCheckFunction();
      localStorage.setItem(
        STORAGEKEY.SESSION_TIMEOUT,
        new Date().toISOString()
      );

      navigate("/home");
    },
  });

  useEffect(() => {
    handleCheckStoreClosed();
  }, [config, localStorage.getItem(STORAGEKEY.SESSION_ID)]);

  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <div className="title-bg position-absolute w-100 start-0 z-index-1">
            {/* {process.env.REACT_APP_MODE === "pavilion" && (
              <label className="position-absolute start-0 end-0 title-margin font-28 fw-bold line-height-45 hotel-title">
                The Authentic <br />
                South Indian Restaurant
              </label>
            )} */}
            <img
              src={`/assets/${process.env.REACT_APP_MODE}/logo/logo.png`}
              alt=""
              className="splash-logo-img mt-3 pt-2"
            />
          </div>
          <div className="h-50 position-absolute w-100 d-flex flex-column justify-content-end pb-5">
            {storeClosed ? (
              <div
                className="position-fixed w-100"
                style={{
                  bottom: "4vh",
                }}>
                <div className="d-flex w-100 d-flex justify-content-center align-content-center">
                  <span
                    className="splash-text text-center text-black"
                    style={{
                      fontSize: 24,
                      maxWidth: "90%",
                    }}>
                    <strong>We are not taking orders at this moment</strong>
                  </span>
                </div>
              </div>
            ) : (
              <>
                <div className="terms-service">
                  <Form className="" onSubmit={formik.handleSubmit}>
                    <button
                      type="submit"
                      disabled={!formik.isValid || loading}
                      className="continue-as-guest d-flex justify-content-center text-white align-items-center mb-2 mt-4 me-3 ms-3">
                      Continue as Guest
                    </button>
                    <div className="pt-2 pb-2 ms-3 me-4 d-flex ps-4">
                      <span className="splash-text text-start text-black">
                        By clicking "Continue as Guest" I agree to Solution
                        Details'{" "}
                        <strong>
                          <Link to="/terms-of-use" className="text-black">
                            Terms of Use
                          </Link>
                        </strong>{" "}
                        and{" "}
                        <strong>
                          <Link to="/privacy-policy" className="text-black">
                            Privacy Policy.
                          </Link>
                        </strong>
                      </span>
                    </div>
                    <Form.Group>
                      <div className="pb-2 d-flex ms-3 me-4">
                        <Form.Check
                          type="checkbox"
                          id="promotional"
                          name="promotional"
                          value={`promotional`}
                          label=""
                          onChange={formik.handleChange}
                        />
                        <span className="splash-text text-start text-black">
                          I do not wish to receive any marketing or promotional
                          materials.
                        </span>
                      </div>
                    </Form.Group>
                  </Form>
                </div>
                <div className="powered-by-evolut position-fixed d-flex justify-content-center bottom-0 align-items-center">
                  <span>
                    Powered by Solution Details - Evolut POS & Intelli Table QR
                  </span>
                </div>
              </>
            )}
          </div>
          <div
            class={`splash-img-container w-100 ${
              storeClosed ? "splash-img-closed-container" : ""
            }`}>
            <img
              src={`/assets/${process.env.REACT_APP_MODE}/background/splash.png`}
              alt=""
              className="w-100 img-splash"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SplashScreen;
