import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    config: {},
    welcomeBanner: "",
    iconConfig: {},
    recommendations: [],
    openConfetti: false,
    storeClosed: false,
  },
  reducers: {
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    setWelcomeBanner: (state, action) => {
      state.welcomeBanner = action.payload;
    },
    setIconConfig: (state, action) => {
      state.iconConfig = action.payload;
    },
    setRecommendations: (state, action) => {
      state.recommendations = action.payload;
    },
    setOpenConfetti: (state, action) => {
      state.openConfetti = action.payload;
    },
    setStoreClosed: (state, action) => {
      state.storeClosed = action.payload;
    },
  },
});

export const appReducer = appSlice.reducer;
export const {
  setConfig,
  setWelcomeBanner,
  setIconConfig,
  setRecommendations,
  setOpenConfetti,
  setStoreClosed,
} = appSlice.actions;
